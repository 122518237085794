import { useWindowSize } from '@vueuse/core';
import debounce from 'lodash/throttle.js';

import { breakpoints } from '~/helpers';

type MediaQuerySize = 'phone' | 'tablet' | 'desktop' | 'large-desktop';

const isStrictSize = (screenSize: number, breakpoint: number) =>
  screenSize < breakpoint;
const isSize = (screenSize: number, breakpoint: number) =>
  screenSize <= breakpoint;

const isSizeResolver = (strictly: boolean) =>
  strictly ? isStrictSize : isSize;
/**
 * WARNING : This will not work in a SSR environment, use with caution
 * @param size
 * @returns
 */
export function useMatchMediaQuery(
  size: MediaQuerySize,
  strictly: boolean = false,
): Ref<boolean> {
  const { width } = useWindowSize();

  const resolveSizeCheck = isSizeResolver(strictly);

  const doesScreenSizeMatchQuery = (screenSize: number) => {
    switch (size) {
      case 'phone':
        return resolveSizeCheck(screenSize, breakpoints.sm);

      case 'tablet':
        return resolveSizeCheck(screenSize, breakpoints.md);

      case 'desktop':
        return resolveSizeCheck(screenSize, breakpoints.lg);

      case 'large-desktop':
        return screenSize > breakpoints.lg;
    }
  };

  const onScreenResize = debounce(
    () => {
      const isMatching = doesScreenSizeMatchQuery(width.value);

      if (isMatching !== matchMediaQuery.value) {
        matchMediaQuery.value = isMatching;
      }
    },
    16,
    { leading: true },
  );

  const matchMediaQuery = ref<boolean>(doesScreenSizeMatchQuery(width.value));

  onMounted(() => {
    window.addEventListener('resize', onScreenResize);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('resize', onScreenResize);
  });

  return matchMediaQuery;
}
